import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MenuItem, MessageService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { SettingsService } from 'src/app/service/dashboard/settings.service'

@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.css']
})
export class PersonaComponent implements OnInit, OnDestroy {
  aiForm: FormGroup

  activeIndex: number = 0
  isLoading = false

  items: MenuItem[] = [
    {
      label: 'Who are you?'
      // command: (event: any) => this.messageService.add({severity:'info', summary:'First Step', detail: event.item.label})
    },
    {
      label: 'Who do you help?'
      // command: (event: any) => this.messageService.add({severity:'info', summary:'Second Step', detail: event.item.label})
    },
    {
      label: 'How do you serve?'
      // command: (event: any) => this.messageService.add({severity:'info', summary:'Third Step', detail: event.item.label})
    }
  ]

  private errorSubscription?: Subscription

  constructor (
    private readonly fb: FormBuilder,
    private readonly messageService: MessageService,
    private readonly settingsService: SettingsService
  ) {
    this.aiForm = this.fb.group({
      persona: [''],
      values: [''],
      audience: [''],
      context: [''],
      style: [''],
      voice: ['']
    })
  }

  ngOnDestroy (): void {
    this.errorSubscription?.unsubscribe()
  }

  ngOnInit (): void {
    this.errorSubscription = this.settingsService.errorObservable$.subscribe({
      next: (error) => {
        console.log('🚀 ~ PersonaComponent ~ ngOnInit ~ error:', error)
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error
        })
      }
    })
    this.settingsService.loadingObservable$.subscribe({
      next: (loadingState) => {
        this.isLoading = loadingState
      }
    })
    this.settingsService.personaObservable$.subscribe({
      next: (persona) => {
        if (persona !== undefined) {
          this.aiForm.patchValue({
            persona: persona.persona,
            values: persona.values,
            audience: persona.audience,
            context: persona.context,
            style: persona.style,
            voice: persona.voice
          })
        } else {
          console.log('🚀 ~ PersonaComponent ~ ngOnInit ~ else:', 'else')
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error saving.'
          })
        }
      }
    })
    this.settingsService.getPersonaSettings()
  }

  onSaveAI(): void {
    this.settingsService.storePersonaSettings(
      this.aiForm.value.persona as string,
      this.aiForm.value.audience as string,
      this.aiForm.value.style as string,
      this.aiForm.value.values as string,
      this.aiForm.value.voice as string,
      this.aiForm.value.context as string
    ).subscribe({
      next: (response) => {
        if (response !== undefined) {
          if (this.activeIndex < 2) {
            this.activeIndex++
          } else if (this.activeIndex === 2) {
            this.messageService.add({
              severity: 'success',
              summary: 'Confirmed',
              detail: 'AI persona settings saved'
            })
          }
        }
      }
    })
  }

  onActiveIndexChange(event: number): void {
    this.activeIndex = event
  }
}
