<p-card>
  <ng-template pTemplate="header">
    <div class="container-row">
      <div style="padding: 1rem; flex: 1 1 auto; ">
        <h2>{{ title }}</h2>
        <p>Don't forget to save your content after you edit below!</p>
      </div>
      <i class="pi pi-times" style="margin: 1rem; cursor: pointer; align-self: flex-start;" (click)="cancel()"></i>
    </div>
  </ng-template>
  <textarea 
    *ngIf="bodyContent !== ''"
    autosize 
    class="styled-textarea"
    [(ngModel)]="bodyContent" 
  ></textarea>
  <div *ngFor="let content of bodyList; let i = index">
    <textarea 
      autosize 
      class="styled-textarea"
      [(ngModel)]="bodyList[i]" 
    ></textarea>
    <p-divider></p-divider>
  </div>
  <ng-template pTemplate="footer">
    <p-button 
      p-button
      styleClass="p-button-info"
      [style]="{ 'min-width': '200px' }"
      label="Copy to clipboard" 
      (click)="copy()" />
    <p-button
      p-button
      [style]="{ 'min-width': '200px', 'margin-left':'1rem' }" 
      label="Save" 
      (click)="save()" />
  </ng-template>
</p-card>
