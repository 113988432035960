<div class="container">
  <p-card header="Get ready to outperform, outpost, and outlast your competition with A.I."
    [style]="{ 'width': '500px', 'align-self': 'center', 'align-content': 'space-between', 'padding': '1rem' }">
    <ng-template pTemplate="header">
      <img alt="Card" src="../../../assets/icons/background_banner.png" />
    </ng-template>
    <p>Enter the email you used to when you purchased. We'll go to the next step if your info checks out ;)</p>
    <ng-template pTemplate="footer" [formGroup]="loginForm" >
      <input 
        *ngIf="!isLoading" 
        type="email" 
        pInputText 
        placeholder="Email" 
        formControlName="email"
        style="width: 100%;" 
      />
      <input 
        *ngIf="!isLoading" 
        type="password" 
        pInputText 
        placeholder="Password" 
        formControlName="password"
        style="width: 100%; margin-top: 1rem;" 
      />
      <p-button 
        *ngIf="!isLoading" 
        label="Sign Up" 
        (click)="onEmailPasswordSubmit()"
        styleClass="p-button-success"
        [style]="{ 'width': '100%', 'margin-top': '1rem'  }"
      ></p-button>
      <div
        *ngIf="errorMessage !== ''"
        style="background-color: lightpink; width: 100; border-radius: 0.5rem; height: 48px; align-items: center; display: flex; padding: 1rem; margin-top: 1rem;"
      >
        <p>{{errorMessage}}</p>
      </div>
      <p-button 
        *ngIf="!isLoading" 
        label="Log in to existing account"
        styleClass="p-button-text p-button-secondary" 
        (click)="switchToLogin()"
        [style]="{ 'width': '100%', 'margin-top': '1rem'  }"
      ></p-button>
    </ng-template>
    <p-progressSpinner *ngIf="isLoading" [style]="{ 'height': '32px', 'margin': '1rem' }"></p-progressSpinner>
  </p-card>
</div>
