import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { DashboardService } from 'src/app/service/dashboard/dashboard.service'
import { InterviewService } from 'src/app/service/interview.service'
import { NavigationService } from 'src/app/service/util/navigation.service'

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent implements OnInit, OnDestroy {
  isLoading = false
  isSuggestionLoading = false
  isTopicsLoading = false

  interviewPair: Array<{
    question: string
    answer: string
  }> = []

  interviewPairSteps: MenuItem[] = []

  currentQuestionIndex = 0
  currentInput: string = ''

  formGroup!: FormGroup

  private errorSubscription?: Subscription

  progress = 0
  progressRemaining = 4
  isFirstTimeOnboarding = false
  currentCharacterCount: number = 0
  isOverLimit: boolean = false
  constructor(
    private readonly interviewService: InterviewService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly dashboardService: DashboardService,
    private readonly navigationService: NavigationService
  ) {
    /** */
  }

  ngOnInit(): void {
    this.dashboardService
      .getFirstTimeUserStatus()
      .subscribe((status) => (this.isFirstTimeOnboarding = status))
    this.interviewService.isLoadingObservable$.subscribe(
      (isLoading: boolean) => (this.isLoading = isLoading)
    )
    this.errorSubscription = this.interviewService.errorObservable$.subscribe(
      (error: string) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error
        })
      }
    )
    this.interviewService.interviewQuestionsObservable$.subscribe(
      (questions: Array<{ question: string, answer: string }>) => {
        this.interviewPair = questions ?? []
        this.interviewPairSteps = this.interviewPair.map((pair, index) => {
          return {
            command: () => {
              this.currentQuestionIndex = index
              this.currentInput =
                this.interviewPair[this.currentQuestionIndex]?.answer
            }
          }
        })
      }
    )
    this.interviewService.currentIndexObservable$.subscribe((index: number) => {
      this.currentQuestionIndex = index ?? 0
      this.currentInput = this.interviewPair[this.currentQuestionIndex]?.answer

      const questionsAnswered = this.interviewPair.filter(
        (pair) =>
          pair?.answer !== undefined &&
          pair?.answer !== '' &&
          pair?.answer !== null
      ).length
      this.progress = questionsAnswered * 25
      this.progressRemaining =
        questionsAnswered < 4 ? 4 - questionsAnswered : 0
    })
    this.interviewService.contentSuggestionsObservable$.subscribe(
      (suggestion: string) => (this.currentInput = suggestion)
    )
    this.interviewService.contentSuggestionLoadingObservable$.subscribe(
      (isLoading: boolean) => (this.isSuggestionLoading = isLoading)
    )
    this.interviewService.topicObservable$.subscribe(
      (topics: Array<{ topic: string, objective: string }>) => {
        this.isTopicsLoading = false
        this.confirmationService.confirm({
          header: 'Your topics are ready!',
          message: 'Would you like to view them now?',
          acceptLabel: 'Yes! Take me there!',
          accept: () => {
            this.navigationService.navigateToVault('topic')
          }
        })
      }
    )
    this.interviewService.getInterviewQuestions()
  }

  ngOnDestroy (): void {
    this.errorSubscription?.unsubscribe()
  }

  getSuggestionClick (): void {
    this.interviewService.getSuggestion(
      this.interviewPair[this.currentQuestionIndex]?.question,
      {
        topic: this.interviewPair[this.currentQuestionIndex]?.question,
        objective: ''
      }
    )
  }

  onAnswerSubmitClick (event?: Event): void {
    event?.preventDefault()
    if (this.interviewPair[this.currentQuestionIndex] !== undefined) {
      this.interviewPair[this.currentQuestionIndex].answer = this.currentInput
      this.onNextQuestionClick()
    }
  }

  onPreviousQuestionClick(): void {
    if (this.currentQuestionIndex <= 0) {
      this.currentQuestionIndex = this.interviewPair.length - 1
      this.currentInput = this.interviewPair[this.currentQuestionIndex]?.answer
    } else {
      this.currentQuestionIndex--
      this.currentInput = this.interviewPair[this.currentQuestionIndex]?.answer
    }
    this.updateCharacterCount()
  }

  onNextQuestionClick(): void {
    if (this.currentQuestionIndex >= this.interviewPair.length - 1) {
      this.currentQuestionIndex = 0
      this.currentInput = this.interviewPair[this.currentQuestionIndex]?.answer
    } else {
      this.currentQuestionIndex++
      this.currentInput = this.interviewPair[this.currentQuestionIndex]?.answer
    }
    this.interviewService.saveUserInterviewAnswer(
      this.currentQuestionIndex,
      this.interviewPair
    )
    this.updateCharacterCount()
  }

  onDoneClick(): void {
    this.confirmationService.confirm({
      header: "Are you sure you're done?",
      message:
        'Generating your topics will take a minute to load.  Are you sure that you want to continue?',
      accept: () => {
        this.isTopicsLoading = true
        const updatedIndex = this.currentQuestionIndex
        const saveIndex =
          updatedIndex >= this.interviewPair.length - 1
            ? 0
            : this.currentQuestionIndex
        this.interviewService.getInterviewTopics(saveIndex, this.interviewPair)
      }
    })
  }

  onReset (): void {
    this.confirmationService.confirm({
      header: 'Are you sure you want to reset?',
      message:
        'This will clear all of your answers and start over.  Are you sure you want to continue?',
      accept: () => {
        this.currentInput = ''
        this.interviewPair = []
        this.currentQuestionIndex = 0
        this.updateCharacterCount()
        this.interviewService.deleteUserInterviewAnswers()
      }
    })
  }

  updateCharacterCount(): void {
    const words = this.currentInput?.match(/\S+/g) ?? []
    this.currentCharacterCount = words.length
    this.isOverLimit = this.currentCharacterCount > 200
  }
}
