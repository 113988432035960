<!-- scripts -->
<script src="https://accounts.google.com/gsi/client" async defer></script>
<script src="https://apis.google.com/js/platform.js?onload=onGoogleLibraryLoad" async defer></script>
<html lang="en">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<!-- scripts -->
<!-- navbar -->
<p-menubar *ngIf="( loggedInObserver$ | async )"
  [style]="{ 'background-color': 'transparent',  'height': 'fit-content' }">
  <ng-template pTemplate="start">
    <div class="horizontal-container">
      <img src="../assets/icons/banner.png" height="32" />
      <p>{{environment}}</p>
    </div>
  </ng-template>
  <ng-template pTemplate="end">
    <div class="horizontal-container">
      <p-button icon="pi pi-cog" styleClass="p-button-link p-button-lg" (click)="onSettingsClick()"></p-button>
    </div>
  </ng-template>
</p-menubar>
<div style="display: flex; flex-direction: row; width: 100vw;">
  <p-card *ngIf="( loggedInObserver$ | async )"
    [style]="{ 'height': '100%', 'margin-top': '1.2rem', 'margin-right': '0.5rem', 'min-height': '80vh', 'display': 'flex', 'flex-direction': 'column' }">
    <h4>Main Pages</h4>
    <p-menu [model]="menuItems" [style]="{ 'border': 'none' }"></p-menu>
  </p-card>
  <div stlye="display:flex; flex-direction: column">
    <router-outlet></router-outlet>
  </div>
</div>
<small>{{version}}</small>
<footer>
  <div class="footer-links">
    <a (click)="onTermsClick('terms')">Terms</a>
    <p> | </p>
    <a (click)="onTermsClick('privacy')">Privacy</a>
    <p> | </p>
    <a (click)="onTermsClick('cookies')">Cookies</a>
    <p> | </p>
    <a>Contact Us</a>
    <br />
  </div>
  <p class="footer-links">© 2023 Outbrand, LLC. - All Rights Reserved</p>
</footer>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
