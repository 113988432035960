<div class="row">
  <div style="margin: auto; width: 60vw; padding: 1rem;">
    <h2 *ngIf="!isContentLoading && contentResult.videoScripts?.length === 0">You must be lost 🤔 You posts will appear here after you generate some content.</h2>

    <div *ngIf="isContentLoading" 
      style="background-color: var(--color-light-red); width: 100%; border-radius: 0.5rem; height: 48px; align-items: center; display: flex; padding: 1rem; margin-bottom: 1rem; flex-direction: row; ">
      <p-progressSpinner [style]="{ 'width': '24px', 'height': '24px', 'margin': 'auto' }"></p-progressSpinner>
      <strong style="margin: 1rem;">Please wait for the rest of your content to load before leaving this page.</strong>
    </div>

    <div 
      *ngIf="!isContentLoading && contentResult.videoScripts?.length ?? 0 > 0"
      style="background-color: var(--color-light-grey); width: 100%; border-radius: 0.5rem; display: flex; padding: 1rem; margin-bottom: 1rem; flex-direction: row; justify-content: space-between; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); border: 1px solid var(--color-light-grey);">
      <h3 style="width: 25%; align-items: center;">Get help from your AI CEO</h3>
      <div style="flex-grow: 1; margin-left: 1rem">
        <p style="font-size: medium;">Your content looks great!  Would you like to generate more topics based on this content?</p>
        <p-button p-button pTooltip="Click here for help and examples" 
          styleClass="p-button-lg"
          [style]="{ 'width':'100%', 'text-align': 'start' }"
          label="Get more topics"
          (click)="getTopicsFromContent()"></p-button>
      </div>
    </div>

    <h1 *ngIf="contentResult.videoScripts?.length ?? 0 > 0">Video Scripts</h1>
    <p-card *ngFor="let videoScript of contentResult.videoScripts; let i = index" class="container">
      <ng-template pTemplate="header">
        <div style="display: flex; flex-direction: row; justify-content: end; width: 100%; padding: 1rem">
          <div style="display: flex; flex-direction: row">
            <p-button [style]="{ 'margin-top': '0.5rem', 'align': 'right' }" label="Save" icon="pi pi-save"
              (click)="saveCurrentContent(contentResult.videoScripts[i])"></p-button>
            <p-button [style]="{ 'margin-top': '0.5rem', 'margin-left': '0.5rem', 'align': 'right' }" label="Copy"
              icon="pi pi-copy" (click)="copyToClipboard(videoScript.content)"></p-button>
          </div>
        </div>
      </ng-template>
      <textarea pInputTextarea [autoResize]="true" rows="15" class="styled-textarea"
        [(ngModel)]="contentResult.videoScripts[i].content"></textarea>
    </p-card>
    <h1 *ngIf="contentResult.videoBullets !== undefined && contentResult.videoBullets?.length ?? 0 > 0">Video Bullet
      Points</h1>
    <p-card *ngFor="let bullets of contentResult.videoBullets; let i = index" class="container">
      <ng-template pTemplate="header">
        <div style="display: flex; flex-direction: row; justify-content: end; width: 100%; padding: 1rem">
          <div style="display: flex; flex-direction: row">
            <p-button [style]="{ 'margin-top': '0.5rem', 'align': 'right' }" label="Save" icon="pi pi-save"
              (click)="saveCurrentContent(contentResult.videoBullets[i])"></p-button>
            <p-button [style]="{ 'margin-top': '0.5rem', 'margin-left': '0.5rem', 'align': 'right' }" label="Copy"
              icon="pi pi-copy" (click)="copyToClipboard(bullets.content)"></p-button>
          </div>
        </div>
      </ng-template>
      <textarea pInputTextarea [autoResize]="true" rows="5" class="styled-textarea"
        [(ngModel)]="contentResult.videoBullets[i].content"></textarea>
    </p-card>
    <h1 *ngIf="contentResult.posts !== undefined && contentResult.posts?.length ?? 0 > 0">Personal Post</h1>
    <p-card *ngFor="let post of contentResult.posts; let i = index" class="container">
      <ng-template pTemplate="header">
        <div style="display: flex; flex-direction: row; justify-content: end; width: 100%; padding: 1rem">
          <div style="display: flex; flex-direction: row">
            <p-button [style]="{ 'margin-top': '0.5rem', 'align': 'right' }" label="Save" icon="pi pi-save"
              (click)="saveCurrentContent(contentResult.posts[i])"></p-button>
            <p-button [style]="{ 'margin-top': '0.5rem', 'margin-left': '0.5rem', 'align': 'right' }" label="Copy"
              icon="pi pi-copy" (click)="copyToClipboard(post.content)"></p-button>
          </div>
        </div>
      </ng-template>
      <textarea pInputTextarea [autoResize]="true" rows="15" class="styled-textarea"
        [(ngModel)]="contentResult.posts[i].content"></textarea>
    </p-card>
    <h1 *ngIf="contentResult.tweets?.content !== undefined && contentResult.tweets?.content?.length ?? 0 > 0">Tweets
    </h1>
    <p-card *ngFor="let tweet of contentResult.tweets.content; let i = index" class="container">
      <ng-template pTemplate="header">
        <div style="display: flex; flex-direction: row; justify-content: end; width: 100%; padding: 1rem">
          <div style="display: flex; flex-direction: row">
            <p-button [style]="{ 'margin-top': '0.5rem', 'align': 'right' }" label="Save" icon="pi pi-save"
              (click)="saveCurrentContent(contentResult.tweets)"></p-button>
            <p-button [style]="{ 'margin-top': '0.5rem', 'margin-left': '0.5rem', 'align': 'right' }" label="Copy"
              icon="pi pi-copy" (click)="copyToClipboard(tweet)"></p-button>
          </div>
        </div>
      </ng-template>
      <textarea pInputTextarea [autoResize]="true" rows="5" class="styled-textarea"
        [(ngModel)]="contentResult.tweets.content[i]"></textarea>
    </p-card>
  </div>
</div>

<p-dialog [(visible)]="isLoading" [modal]="true" [style]="{ 'width': '30%', 'margin': 'auto' }" [showHeader]="false">
  <div style="display: flex; flex-direction: column; align-items: center;">
    <p-progressSpinner
      [style]="{ 'width': '64px', 'height': '64px', 'margin': 'auto', 'margin-top': '2rem' }"></p-progressSpinner>
    <strong style="margin-top: 1rem;">We're getting your hooks. Please wait ☕️</strong>
  </div>
</p-dialog>

<p-dialog 
  [(visible)]="showHooksDialog" [modal]="true" [style]="{ 'width': '50%', 'margin': 'auto' }" header="Pick 2 hooks for your content!" [closable]="false" >
  <div *ngFor="let hook of hooks; let i = index"
    style="background-color: #f5f6f84D; width: 100%; border-radius: 0.5rem; padding-left: 1rem; padding-right: 1rem; padding-top: 0.5rem; display: flex; flex-direction: row; margin-top: 1rem; border: 3px solid #2196F399; align-items: center; justify-content: space-between;">
    <h3 style="padding-right: 2rem;">{{ hook.value }}</h3>
    <p-toggleButton 
      [style]="{ 'min-width': '150px', 'border': '1px solid #f5f6f8'}" 
      onLabel="Applied" offLabel="Click here to keep"
      [(ngModel)]="hooks[i].selected">
      <h1>Toaster</h1>
    </p-toggleButton>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="Continue to content" styleClass="p-button-lg" (click)="onHooksSelected()"></p-button>
  </ng-template>
</p-dialog>
