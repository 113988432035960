<div style="margin-top: 2rem">
  <div style="display: flex; flex-direction: row">
    <h3 style="padding-bottom: 1rem">
      Question
      {{ currentQuestionIndex !== undefined ? currentQuestionIndex + 1 : 1 }} of
      {{ interviewPair.length }}
    </h3>
    <p-button
      p-button
      styleClass="p-button-text"
      icon="pi pi-question-circle"
      pTooltip="You don't have to answer all of these questions. Click the blue button to finish"
    />
  </div>
  <p-steps
    [model]="interviewPairSteps"
    [activeIndex]="currentQuestionIndex"
    [readonly]="false"
  ></p-steps>
</div>
<div class="row">
  <div style="min-height: 80vh; width: 60vw">
    <p-skeleton
      *ngIf="isLoading"
      width="60%"
      height="4rem"
      [style]="{ margin: 'auto', 'margin-top': '9rem' }"
    ></p-skeleton>
    <p-skeleton
      *ngIf="isLoading"
      width="60%"
      height="12rem"
      [style]="{ margin: 'auto', 'margin-top': '1rem' }"
    ></p-skeleton>
    <div
      *ngIf="!isLoading && interviewPair.length > 0"
      [style]="{ width: '60%', margin: 'auto', 'margin-top': '6rem' }"
    >
      <h1>
        {{
          currentQuestionIndex !== undefined &&
          interviewPair &&
          interviewPair[currentQuestionIndex] !== undefined
            ? interviewPair[currentQuestionIndex].question
            : interviewPair && interviewPair[0].question
        }}
      </h1>
      <textarea
        *ngIf="!isSuggestionLoading"
        pInputTextarea
        rows="5"
        class="styled-textarea"
        placeholder="Type your answer here..."
        [style]="{ width: '100%' }"
        [autoResize]="true"
        [(ngModel)]="currentInput"
        (ngModelChange)="updateCharacterCount()"
      ></textarea>
      <p-skeleton
        *ngIf="isSuggestionLoading"
        width="100%"
        height="12rem"
        [style]="{ margin: 'auto', 'margin-top': '1rem' }"
      ></p-skeleton>
      <div class="container-row">
        <p-button
          *ngIf="progressRemaining <= 0"
          p-button
          [style]="{ 'min-width': '200px', }"
          label="Get AI Suggestion"
          styleClass="p-button-secondary"
          [disabled]="isSuggestionLoading"
          (click)="isSuggestionLoading ? null : getSuggestionClick()"
        />
        <div
          class="character-counter"
          [ngClass]="{ 'over-limit': isOverLimit }"
        >
          {{ currentCharacterCount }} <span class="character-range">/ 200</span>
        </div>
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between; margin-top: 6rem"
    >
      <div class="row">
        <p-button
          p-button
          styleClass="p-button-text p-button-lg p-button-danger"
          [style]="{ 'min-width': '100px' }"
          label="Reset"
          (click)="onReset()"
        ></p-button>
        <p-button
          p-button
          styleClass="p-button-text p-button-lg"
          [style]="{ 'min-width': '100px', 'margin-left': '1rem' }"
          label="Previous"
          (click)="onPreviousQuestionClick()"
        />
        <p-button
          p-button
          styleClass="p-button-text p-button-lg"
          [style]="{ 'min-width': '100px' }"
          label="Skip"
          (click)="onNextQuestionClick()"
        />
      </div>
      <div class="row">
        <ng-container *ngIf="progressRemaining <= 0">
          <ng-container *ngIf="!isOverLimit; else disabledGetTopicsBtn">
            <p-button
              p-button
              styleClass="p-button-text"
              [style]="{
                'margin-right': '1rem',
                'min-width': '200px',
                height: '48px'
              }"
              icon="pi pi-chevron-right"
              label="Get Topics & Go To Next Step"
              (click)="onDoneClick()"
            ></p-button>
          </ng-container>
          <ng-template #disabledGetTopicsBtn>
            <p-button
              p-button
              styleClass="p-button-text"
              [style]="{
                'margin-right': '1rem',
                'min-width': '200px',
                height: '48px'
              }"
              icon="pi pi-chevron-right"
              label="Get Topics & Go To Next Step"
              [disabled]="true"
            ></p-button>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="!isOverLimit; else disabledSaveAnswerBtn">
          <p-button
            pTooltip="Save your answer and move onto the next one"
            tooltipPosition="top"
            styleClass="p-button-success"
            [style]="{ margin: 'auto', width: '200px', height: '48px' }"
            icon="pi pi-save"
            label="Save Answer"
            (click)="onAnswerSubmitClick($event)"
          ></p-button>
        </ng-container>
        <ng-template #disabledSaveAnswerBtn>
          <p-button
            pTooltip="Save your answer and move onto the next one"
            tooltipPosition="top"
            styleClass="p-button-success"
            [style]="{ margin: 'auto', width: '200px', height: '48px' }"
            icon="pi pi-save"
            label="Save Answer"
            [disabled]="true"
          ></p-button>
        </ng-template>
      </div>
    </div>
  </div>
  <p-card [style]="{ 'margin-left': '1rem', width: '26rem', height: '600px' }">
    <h2>Train Your AI Clone</h2>
    <strong *ngIf="progressRemaining > 0"
      >{{ progressRemaining }} questions left until your AI knows you</strong
    >
    <p *ngIf="progressRemaining === 0">
      Your AI knows you and can give you suggestions!
    </p>
    <p-progressBar
      [value]="progress"
      [showValue]="false"
      [style]="{ 'margin-top': '1rem' }"
      [color]="progressRemaining === 0 ? 'lightgreen' : 'var(--primary-color)'"
    ></p-progressBar>
    <div style="display: flex; flex-direction: column; margin-top: 1rem">
      <p>
        Use these questions to train your own AI to create content around your
        business. The more you give us the better this app will work for you.
        You can click "Done" any time you want to generate Topics for content.
      </p>
      <p>
        <strong style="color: green">Done:</strong> Click here when you are
        ready to generate topics.
      </p>
      <p>
        <strong style="color: var(--primary-color)">Save Answer:</strong> Submit
        your answer to the question and move on to the next question.
      </p>
      <p>
        <strong style="color: var(--primary-color)">Get AI Suggestion:</strong>
        Once you answer 4 questions our AI can take over and give you answers.
      </p>
      <p><strong>Previous:</strong> Go back to the previous question.</p>
      <p>
        <strong>Skip:</strong> You can skip any question you don't want to
        answer.
      </p>
    </div>
  </p-card>
</div>

<p-dialog
  [(visible)]="isTopicsLoading"
  [modal]="true"
  [style]="{ width: '30%', margin: 'auto' }"
  [showHeader]="false"
>
  <div style="display: flex; flex-direction: column; align-items: center">
    <p-progressSpinner
      [style]="{
        width: '64px',
        height: '64px',
        margin: 'auto',
        'margin-top': '2rem'
      }"
    ></p-progressSpinner>
    <strong style="margin-top: 1rem"
      >Please wait. We are learning about your business and training our
      AI.</strong
    >
  </div>
</p-dialog>

<p-dialog
  [(visible)]="isFirstTimeOnboarding"
  [modal]="true"
  [style]="{ width: '40%', margin: 'auto' }"
  header="Get ready to Outbrand your competition"
>
  <p>Ok, Ok, Ok… Welcome!!</p>
  <p>
    I am here to serve you and your business! I want to ask you about your
    business and get to know you. Using your answers I'll learn about your
    business & your audience.
  </p>
  <p>
    This info will be saved into your AI CEO, which becomes more powerful every
    time you use OutBrand and gets better and better at helping you.
  </p>
  <p>
    We combine this information with what currently works best in the content
    arena, create HOT content topics for you, give you the best questions the
    extract that out wisdom out of you, suggest answers for your content
    creation interviews, and much more.
  </p>
  <p>Let's make you WIN!!</p>
  <br />
  <strong>How to answer the interview questions:</strong>
  <p>
    Answer each question in 50-100 words, try to answer as many as possible if
    there's a question you're not not in the mood for, you can skip it.
  </p>
  <p>
    The more you answer the more information we have to help you. You don't have
    to answer all the questions now.
  </p>
  <ng-template pTemplate="footer" style="justify-content: end">
    <p-button
      icon="pi pi-check"
      (click)="isFirstTimeOnboarding = false"
      label="OK"
      styleClass="p-button-lg"
    ></p-button>
  </ng-template>
</p-dialog>
