import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { DashboardService } from 'src/app/service/dashboard/dashboard.service'
import { Observable } from 'rxjs'
import { Summary } from 'src/app/model/apis/summary.model'
import { Question } from 'src/app/model/views/question.model'
import { MessageService } from 'primeng/api'
import { InterviewService } from 'src/app/service/interview.service'
import { NavigationService } from 'src/app/service/util/navigation.service'

@Component({
  selector: 'app-topicpanel',
  templateUrl: './topicpanel.component.html',
  styleUrls: ['./topicpanel.component.css']
})
export class TopicPanelComponent implements OnInit {
  @Input() isCollapsed = false
  @Output() collapseEvent = new EventEmitter<{
    key: string
    collapsed: boolean
  }>()

  isLoading = false
  selectedTopic: { topic: string, objective: string } = {
    topic: '',
    objective: ''
  }

  topics: Array<{ topic: string, objective: string }> = []

  contentLoading$!: Observable<boolean>

  title = ''

  detailsTitle = ''
  detailsDescription = ''
  detailsThumbnail = ''
  detailsLengthMins = ''
  detailsViewCount = ''

  topicEditMode = false
  currentTopic = ''

  constructor (
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dashboardService: DashboardService,
    private readonly messageService: MessageService,
    private readonly interviewService: InterviewService,
    private readonly navigationService: NavigationService
  ) { /** */ }

  ngOnInit (): void {
    this.interviewService.isLoadingObservable$.subscribe({
      next: (isLoading) => (this.isLoading = isLoading)
    })
    this.interviewService.topicSubsetObservable$.subscribe({
      next: (topics) => (this.topics = topics)
    })
    this.interviewService.topicSelectedObservable$.subscribe({
      next: (topic) => {
        if (topic !== undefined) {
          this.selectedTopic = topic
          this.isCollapsed = true
          this.collapseEvent.emit({
            key: 'topic',
            collapsed: this.isCollapsed
          })
          this.currentTopic = this.selectedTopic.topic
        }
      }
    })
    this.contentLoading$ = this.dashboardService.creteLoadingObservable$
    this.interviewService.getRandomTopicsSubset()
  }

  ngAfterViewInit (): void {
    this.changeDetectorRef.markForCheck()
  }

  moveToContent (point: string): void {
    this.dashboardService.movePointToContent(point)
    this.messageService.add({
      severity: 'success',
      summary: 'Confirmed',
      detail: 'Added to draft'
    })
  }

  moveSummariesToContent (items: Summary[]): void {
    const summaryPointsTranscripts = items.map((summary) => summary.title)
    this.dashboardService.movePointsToContent(summaryPointsTranscripts)
  }

  moveTranscriptsToContent (items: Summary[]): void {
    const summaryPointsTranscripts = items.map((summary) => summary.transcript)
    this.dashboardService.movePointsToContent(summaryPointsTranscripts)
  }

  moveQuestionsToContent (questions: Question[]): void {
    this.dashboardService.movePointsToContent(
      questions.map((question) => question.text.replace('\\n\\n', ''))
    )
    this.messageService.add({
      severity: 'success',
      summary: 'Confirmed',
      detail: 'Added to draft'
    })
  }

  onTopicSelected (topic: { topic: string, objective: string }): void {
    this.selectedTopic = topic
  }

  onTopicConfirmation (): void {
    this.interviewService.onTopicSelected(this.selectedTopic)
  }

  onRerollClick (): void {
    this.topics = []
    this.interviewService.getRandomTopicsSubset()
  }

  goToInterview (): void {
    this.navigationService.navigateToInterview()
  }

  onEditTopicSave (): void {
    this.topicEditMode = false
  }

  resetTopic (): void {
    this.selectedTopic = {
      topic: '',
      objective: ''
    }
    this.currentTopic = ''
    this.collapseEvent.emit({
      key: 'topic',
      collapsed: false
    })
  }
}
