import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AnimationOptions } from 'ngx-lottie'

@Component({
  selector: 'app-outputdialog',
  templateUrl: './outputdialog.component.html',
  styleUrls: ['./outputdialog.component.css']
})
export class FacebookpostComponent implements OnInit {
  animationVisible = true

  @Input() bodyContent: string = ''
  @Input() bodyList: string[] = []
  @Input() avatarUrl: string = ''
  @Input() title: string = ''

  @Output() onCancel = new EventEmitter<void>()
  @Output() onCopy = new EventEmitter<string>()
  @Output() onSaveBodyContent = new EventEmitter<string>()
  @Output() onSaveConentList = new EventEmitter<string[]>()
  @Output() onCreateDraft = new EventEmitter<{ title: string, content: string }>()

  subtitle = new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
  options: AnimationOptions = {
    loop: false,
    path: '../../../../assets/animations/celebrate.json'
  }

  ngOnInit(): void {
    this.animationVisible = true
  }

  animationComplete (): void {
    this.animationVisible = false
  }

  cancel (): void {
    this.onCancel.emit()
  }

  save (): void {
    if (this.bodyContent !== '') {
      this.onSaveBodyContent.emit(this.bodyContent)
    } else if (this.bodyList.length > 0) {
      this.onSaveConentList.emit(this.bodyList)
    }
  }

  createDraft (): void {
    this.onCreateDraft.emit({
      title: this.title,
      content: this.bodyContent
    })
    this.onCancel.emit()
  }

  copy (): void {
    this.animationVisible = true
    this.onCopy.emit(this.bodyContent)
  }
}
