<p-panel [toggleable]="true" [(collapsed)]="isCollapsed">
  <ng-template pTemplate="header">
    <div
      style="width: 100%; border-radius: 0.5rem; display: flex;">
      <h1 [ngStyle]="{
        'color': isCollapsed ? 'grey': 'var(--primary-color)'
      }">Finish with a closing</h1>
    </div>
  </ng-template>
  <div>
    <p-skeleton *ngIf="isQuestionLoading" width="100%" height="15rem"
      [style]="{ 'margin': 'auto', 'margin-top': '1rem' }"></p-skeleton>
    <div *ngIf="!isQuestionLoading && this.currentQuestion !== undefined"
      [style]="{ 'padding': '0.5rem' }">
      <h2>{{ currentQuestion }}</h2>
      <p-skeleton *ngIf="isSuggestionsLoading" width="100%" height="15rem" [style]="{ 'margin': 'auto' }"></p-skeleton>
      <textarea *ngIf="!isSuggestionsLoading" pInputTextarea [autoResize]="true" rows="1" class="styled-textarea"
        placeholder="Type your answer here..." [(ngModel)]="currentInput"></textarea> 
      <p-divider></p-divider>  
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-top: 2rem">
      <p-button 
        *ngIf="this.currentQuestion !== undefined" 
        p-button styleClass="p-button-text p-button-lg"
        [style]="{ 'margin': 'auto','height': '32px', }"
        label="Skip" (click)="onSubmitClick()" />
      <div></div>
      <div>
        <p-button p-button pTooltip="Click here if you are done with this step"
          [style]="{ 'margin': 'auto', 'width': '200px', 'height': '48px', 'margin-right': '1rem' }"
          icon="pi pi-chevron-right" label="Go To Next Step"
          (click)="collapseEvent.emit({ key: 'closing', collapsed: true })"></p-button>
        <p-button 
          *ngIf="this.currentQuestion !== undefined" 
          p-button
          pTooltip="Click here if you are done with this step" styleClass="p-button-success"
          [style]="{ 'margin': 'auto', 'width': '200px', 'height': '48px' }" icon="pi pi-save" label="Save Answer"
          (click)="onSubmitClick()"></p-button>
      </div>
    </div>
  </ng-template>
</p-panel>
