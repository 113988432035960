import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ConfirmationService, MessageService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { VaultItem } from 'src/app/model/content/vault.model'
import { InterviewService } from 'src/app/service/interview.service'
import { NavigationService } from 'src/app/service/util/navigation.service'
import { VaultService } from 'src/app/service/vault.service'

@Component({
  selector: 'app-vault',
  templateUrl: './vault.component.html',
  styleUrls: ['./vault.component.css']
})
export class VaultComponent implements OnInit, OnDestroy {
  showVaultContent = false
  selectedVaultItemContent?: VaultItem

  isLoading = false
  isContentView = false
  isTopicView = false
  isDraftView = false

  showCreateTopicDialog = false

  completeVaultList: VaultItem[] = []
  type = ''

  private errorSubscription?: Subscription

  private static readonly SORT_ASCENDING = 1
  private static readonly SORT_DESCENDING = -1
  private static readonly SORT_EQUAL = 0

  constructor (
    private readonly route: ActivatedRoute,
    private readonly interviewService: InterviewService,
    private readonly messageService: MessageService,
    private readonly navigationService: NavigationService,
    private readonly vaultService: VaultService,
    private readonly confirmationService: ConfirmationService
  ) { /** */ }

  ngOnInit (): void {
    this.vaultService.isLoadingObservable$.subscribe((isLoading: boolean) => { this.isLoading = isLoading })
    this.errorSubscription = this.vaultService.errorObservable$.subscribe((error: string) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error
      })
    })
    this.vaultService.completeVaultObservable$.subscribe(
      (completeVaultList: any) => {
        this.completeVaultList = completeVaultList
          .filter((vaultItem: VaultItem) => vaultItem.type === this.type)
          .map((vaultItem: VaultItem) => {
            this.isContentView = vaultItem.type === 'content'
            this.isDraftView = vaultItem.type === 'draft'
            return {
              ...vaultItem,
              displayType:
                vaultItem.type === 'draft'
                  ? 'Draft'
                  : vaultItem.type === 'topic'
                    ? 'Topic'
                    : vaultItem.data.type === 'video_script'
                      ? 'Script'
                      : vaultItem.data.type === 'post'
                        ? 'Post'
                        : vaultItem.data.type === 'tweets'
                          ? 'Tweets'
                          : vaultItem.data.type === 'bullets'
                            ? 'Bullets'
                            : 'unknown'
            }
          })
        this.completeVaultList.sort((a, b) => {
          return (
            new Date(b.data.date).getTime() - new Date(a.data.date).getTime()
          )
        })
      }
    )
    this.vaultService.saveSuccessObservable$.subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'We have saved this for you'
      })
    })
    this.route.params.subscribe((params) => {
      if (params['type'] !== undefined) {
        this.type = params['type']
        this.vaultService.getTheVault()
      }

      this.isContentView = false
      this.isDraftView = false
    })
  }

  ngOnDestroy (): void {
    this.errorSubscription?.unsubscribe()
  }

  onGetMoreClick (): void {
    this.navigationService.navigateToInterview()
  }

  onDeleteClick (vaultItem: VaultItem): void {
    this.confirmationService.confirm({
      header: "There's no going back!",
      message: 'Are you sure that you want to delete this?',
      accept: () => {
        this.vaultService.deleteVaultItem(vaultItem)
      }
    })
  }

  onCreateClick (vaultItem: VaultItem): void {
    this.interviewService.navigateFromCreate(vaultItem)
  }

  onEditClick (vaultItem: VaultItem): void {
    this.interviewService.navigateFromEdit(vaultItem)
  }

  onViewClick (vaultItem: VaultItem): void {
    this.showVaultContent = true
    this.selectedVaultItemContent = vaultItem
  }

  onSaveVaultContent (content: string | string[]): void {
    if (this.selectedVaultItemContent !== undefined) {
      const updatedVaultItem = {
        ...this.selectedVaultItemContent,
        data: {
          ...this.selectedVaultItemContent.data,
          content
        }
      }
      this.vaultService.saveContent(updatedVaultItem)
    }
  }

  onCopyVaultContent (content: string): void {
    this.copyToClipboard(content)
  }

  copyToClipboard (content: string): void {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Copied to clipboard',
          life: 1000
        })
      })
      .catch((err) => {
        console.error('Error copying text: ', err)
      })
  }

  onTopicCreated (topic: string): void {
    this.interviewService.saveTopic(topic)
    this.showCreateTopicDialog = false
  }

  onCustomSort(event: { field: string, order: number }): void {
    this.completeVaultList.sort((mainData, comparisonData) => {
      let value1: any
      let value2: any
      if (event.field === 'topic') {
        value1 =
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          mainData.data.topic?.topic?.toLowerCase() ||
          mainData.data.topic.toLowerCase()
        value2 =
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          comparisonData.data.topic?.topic?.toLowerCase() ||
          comparisonData.data.topic.toLowerCase()
      } else if (event.field === 'date') {
        value1 = new Date(mainData.data.date)
        value2 = new Date(comparisonData.data.date)
      } else if (event.field === 'type') {
        value1 = mainData.displayType
        value2 = comparisonData.displayType
      }
      let result = VaultComponent.SORT_EQUAL
      if (value1 < value2) {
        result = VaultComponent.SORT_DESCENDING
      } else if (value1 > value2) {
        result = VaultComponent.SORT_ASCENDING
      }
      return event.order * result
    })
    this.completeVaultList = [...this.completeVaultList]
  }
}
