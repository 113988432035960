import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MessageService } from 'primeng/api'
import { InterviewService } from 'src/app/service/interview.service'

@Component({
  selector: 'app-interviewclosingpanel',
  templateUrl: './interviewclosingpanel.component.html',
  styleUrls: ['./interviewclosingpanel.component.css']
})
export class InterviewClosingPanelComponent implements OnInit {
  @Input() isCollapsed = true
  @Output() collapseEvent = new EventEmitter<{ key: string, collapsed: boolean }>()
  @Output() questionAnsweredEvent = new EventEmitter<boolean>()

  closingQuestions = [
    'What is the main lesson here?',
    'What’s one thing you’d like them to remember or take from this?',
    'What are 1-5 things you’d recommend your ideal costumer to do based on this?',
    'What would be 1-5 benefits that your audience could get from listening to what you’re saying now?',
    'Is there a mindset shift or a call to action that you’d like to leave them with?'
  ]

  isQuestionLoading = false
  isSuggestionsLoading = false
  currentQuestion?: string = this.closingQuestions[Math.floor(Math.random() * this.closingQuestions.length)]
  currentInput = ''

  constructor (
    private readonly interviewService: InterviewService,
    private readonly messageService: MessageService
  ) { /** */ }

  ngOnInit (): void {
    this.interviewService.contentSuggestionLoadingObservable$.subscribe((isLoading: boolean) => { this.isSuggestionsLoading = isLoading })
  }

  onSubmitClick(): void {
    this.interviewService.addContentQuestionToDraft(
      this.currentInput,
      this.currentQuestion,
      true
    )
    if (this.currentInput !== '') {
      this.collapseEvent.emit({ key: 'closing', collapsed: true })
      this.questionAnsweredEvent.emit(true)
      this.messageService.add({
        severity: 'success',
        detail: 'We\'ve added this to your draft',
        life: 1000
      })
    }

    this.currentQuestion = this.closingQuestions[Math.floor(Math.random() * this.closingQuestions.length)]
    this.currentInput = ''
  }

  onGetSuggestionClick(): void {
    this.interviewService.getSuggestion(this.currentQuestion)
  }

  onQuestionsConfirmation (): void {
    this.isCollapsed = true
  }
}
