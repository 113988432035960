<div class="row">
  <div style="width: 60vw; display: flex; flex-direction: column">
    <strong style="text-align: end;">Click the + or - to open section</strong>
    <app-topicpanel 
      id="topicpanel"
      (collapseEvent)="onCollapseEvent($event)"
      [isCollapsed]="isCollapsedMap.get('topic') ?? true"
    ></app-topicpanel>
    <p-divider></p-divider>
    <app-interviewpanel 
      id="interviewpanel"
      (collapseEvent)="onCollapseEvent($event)"
      (questionAnsweredEvent)="onQuestionAnsweredEvent($event)"
      [isCollapsed]="isCollapsedMap.get('interview') ?? true"
    ></app-interviewpanel>
    <p-divider></p-divider>
    <app-interviewclosingpanel
      id="interviewclosingpanel" 
      (collapseEvent)="onCollapseEvent($event)"
      (questionAnsweredEvent)="onQuestionAnsweredEvent($event)"
      [isCollapsed]="isCollapsedMap.get('closing') ?? true"
    ></app-interviewclosingpanel>
    <p-divider></p-divider>
    <app-draftpanel 
      id="draftpanel"
      (collapseEvent)="onCollapseEvent($event)"
      (resetEvent)="onResetEvent($event)"
      [isCollapsed]="isCollapsedMap.get('draft') ?? true"
    ></app-draftpanel>
  </div>
  <div style="display: flex; flex-direction: column; margin-left: 1rem; width: 26rem;">
    <p-card
      [style]="{ 'height': '600px'}">
      <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true" [style]="{ 'margin-bottom': '2rem' }"></p-steps>
  
      <h2>Your Content Kit</h2>
      <p>Answer more content questions. Create better content.</p>
      <div class="row" style="margin-top: 1rem">
        <div>
          <img src="../../../assets/icons/reward_rookie.png" height="64" width="64" />
          <p style="text-align: center;">Gets Views</p>
        </div>
        <div style="position: relative;">
          <img src="../../../assets/icons/reward_pro.png" height="64" width="64" />
          <p style="text-align: center;">More Likes</p>
          <div *ngIf="trophyProgress < 4" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.75);"></div>
        </div> 
        <div style="position: relative;">
          <img src="../../../assets/icons/reward_superstar.png" height="64" width="64" />
          <p style="text-align: center;">Viral Shares</p>
          <div *ngIf="trophyProgress < 8" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.75);"></div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column;">
        <h3>Completed Steps</h3>
        <p-button
          [style]="topicStepDone ? { 'text-align': 'left', 'margin-top': '0.5rem', 'width': '100%', 'border': 'none'} : { 'text-align': 'left', 'margin-top': '0.5rem', 'width': '100%', 'border': 'none', 'color': 'black'  }"
          (click)="onCollapseEvent({ key: 'topic', collapsed: false })"
          [styleClass]="topicStepDone ? 'p-button-lg p-button-outlined p-button-success' : 'p-button-lg p-button-outlined'"
          label="Get your topic"
          [icon]="topicStepDone ? 'pi pi-check' : 'pi pi-circle'"
        ></p-button>
  
        <p-button
          [style]="interviewStepDone ? { 'text-align': 'left', 'margin-top': '0.5rem', 'width': '100%', 'border': 'none'} : { 'text-align': 'left', 'margin-top': '0.5rem', 'width': '100%', 'border': 'none', 'color': 'black'  }"
          (click)="onCollapseEvent({ key: 'interview', collapsed: false })"
          [styleClass]="interviewStepDone ? 'p-button-lg p-button-outlined p-button-success' : 'p-button-lg p-button-outlined'"
          label="Jump into your interview"
          [icon]="interviewStepDone ? 'pi pi-check' : 'pi pi-circle'"
        ></p-button>
  
        <p-button
          [style]="closingStepDone ? { 'text-align': 'left', 'margin-top': '0.5rem', 'width': '100%', 'border': 'none'} : { 'text-align': 'left', 'margin-top': '0.5rem', 'width': '100%', 'border': 'none', 'color': 'black'  }"
          (click)="onCollapseEvent({ key: 'closing', collapsed: false })"
          [styleClass]="closingStepDone ? 'p-button-lg p-button-outlined p-button-success' : 'p-button-lg p-button-outlined'"
          label="Finish with a closing"
          [icon]="closingStepDone ? 'pi pi-check' : 'pi pi-circle'"
        ></p-button>
  
        <p-button
          [style]="{ 'text-align': 'left', 'margin-top': '0.5rem', 'width': '100%', 'border': 'none', 'color': 'black' }"
          styleClass="p-button-lg p-button-outlined"
          label="Edit your draft"
          icon="pi pi-circle"
          (click)="onCollapseEvent({ key: 'draft', collapsed: false })"
        ></p-button>
      </div>
    </p-card>
  </div>
</div>
