<p-panel #panel [toggleable]="true" [(collapsed)]="isCollapsed">
  <ng-template pTemplate="header">
    <h1
      [ngStyle]="{
        color: isCollapsed ? 'grey' : 'var(--primary-color)'
      }"
    >
      Get your topic
    </h1>
  </ng-template>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 1rem;
    "
  >
    <h3>Choose a topic from our list</h3>
    <div style="display: flex; flex-direction: row">
      <p-button
        [style]="{ 'margin-top': '1rem' }"
        label="Get new topics"
        icon="pi pi-refresh"
        (onClick)="onRerollClick()"
      ></p-button>
    </div>
  </div>
  <div *ngIf="isLoading">
    <p-skeleton
      width="100%"
      height="10rem"
      [style]="{ margin: 'auto', 'margin-bottom': '1rem' }"
    ></p-skeleton>
    <p-skeleton
      width="100%"
      height="10rem"
      [style]="{ margin: 'auto', 'margin-bottom': '1rem' }"
    ></p-skeleton>
    <p-skeleton
      width="100%"
      height="10rem"
      [style]="{ margin: 'auto', 'margin-bottom': '1rem' }"
    ></p-skeleton>
  </div>
  <p-button
    *ngIf="topics.length == 0 && !isLoading"
    [style]="{
      margin: 'auto',
      'margin-top': '1rem',
      width: '100%',
      height: '100px'
    }"
    label="Looks like you haven't answered any questions yet! Go to the interview to get started."
    styleClass="p-button-lg p-button-info"
    (click)="goToInterview()"
  ></p-button>
  <div style="display: flex; flex-direction: column; min-height: 50vh">
    <p-button
      class="hover-button"
      *ngFor="let topic of topics"
      [style]="{
        margin: '0.5rem',
        width: '98%',
        'border-radius': '0.5rem',
        display: 'flex',
        'flex-direction': 'row',
        color: 'black',
        'background-color': 'var(--color-light-grey)',
        border: '1px solid #e4e4e4'
      }"
      (onClick)="onTopicSelected(topic)"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          text-align: start;
          padding-top: 0.5rem;
        "
      >
        <h2 style="margin: 0">{{ topic.topic }}</h2>
        <p>{{ topic.objective }}</p>
      </div>
    </p-button>
  </div>
  <ng-template pTemplate="footer">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        width: 100%;
        padding: 1rem;
      "
    >
      <p-button
        p-button
        pTooltip="Click here if you are done with this step"
        styleClass="p-button-success"
        [style]="{ margin: 'auto', width: '200px', height: '48px' }"
        icon="pi pi-save"
        label="Save Topic"
        (click)="onTopicConfirmation()"
      ></p-button>
    </div>
  </ng-template>
</p-panel>
<div *ngIf="isCollapsed" style="margin-left: 1rem">
  <div
    *ngIf="!topicEditMode"
    style="
      background-color: #f5f6f84d;
      width: 100%;
      border-radius: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.5rem;
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      border: 3px solid #2196f399;
      text-align: center;
    "
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        text-align: start;
        padding-top: 0.5rem;
      "
    >
      <h2 style="margin: 0">{{ currentTopic }}</h2>
      <p></p>
    </div>
    <p-button
      *ngIf="!topicEditMode"
      [style]="{
        'margin-left': '0.5rem',
        align: 'right',
        'margin-right': '0.5'
      }"
      styleClass="p-button-text p-button-secondary"
      icon="pi pi-pencil"
      iconPos="right"
      (onClick)="topicEditMode = true"
    ></p-button>
  </div>
  <div
    *ngIf="topicEditMode"
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <input
      pInputText
      class="p-inputtext-lg"
      [style]="{ 'margin-top': '0.5rem', width: '95%', 'margin-right': '0.5' }"
      [(ngModel)]="currentTopic"
    />
    <p-button
      [style]="{
        'margin-top': '0.5rem',
        align: 'right',
        'margin-right': '0.5'
      }"
      icon="pi pi-save"
      label="Save"
      (onClick)="onEditTopicSave()"
      [disabled]="currentTopic.trim().length === 0"
    ></p-button>
  </div>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: end;
    "
  >
    <p-button
      *ngIf="!topicEditMode"
      [style]="{
        'margin-top': '0.5rem',
        align: 'right',
        'margin-right': '0.5'
      }"
      label="Pick a new topic"
      icon="pi pi-refresh"
      styleClass="p-button-text p-button-danger"
      (onClick)="resetTopic()"
    ></p-button
    >>
  </div>
</div>
