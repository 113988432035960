import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { SocialAuthService } from 'src/app/service/user/socialauth.service'
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { NavigationService } from 'src/app/service/util/navigation.service'
import { FacebookPage } from 'src/app/model/content/facebookpage.model'
import { Panel } from 'primeng/panel'
import { Menu } from 'primeng/menu'
import { FormBuilder, FormGroup } from '@angular/forms'
import { UserAuthService } from 'src/app/service/user/session.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @Input() parentFocusedConnection = 0

  isAccountsLoading = true
  isLoading = false
  isBlocked = false

  zoomConnected = false
  twitterConnected = false
  youtubeConnected = false
  linkedinConnected = false
  facebookConnected = false
  mediumConnected = false

  mediumIntegKey = ''

  accountForm!: FormGroup

  faceebookAuthMenuItems: MenuItem[] = [
    {
      label: 'Facebook Login'
    },
    {
      label: 'Select Page'
    },
    {
      label: 'Select Instagram'
    }
  ]

  facebookAuthMenuItemIndex = 0
  userFacebookPages: FacebookPage[] = []
  userSelectedFacebookPage: FacebookPage | undefined = undefined

  currentView = 'Profile'
  menuItems: MenuItem[] = [
    {
      label: 'Profile',
      icon: 'pi pi-user',
      command: () => {
        this.isBlocked = false
        this.currentView = 'Profile'
      }
    },
    {
      label: 'Zoom',
      icon: 'pi pi-video',
      command: () => {
        this.isBlocked = false
        this.currentView = 'Zoom'
      }
    },
    {
      label: 'Facebook',
      icon: 'pi pi-facebook',
      command: () => {
        this.isBlocked = true
        this.currentView = 'Facebook'
      }
    },
    {
      label: 'LinkedIn',
      icon: 'pi pi-linkedin',
      command: () => {
        this.isBlocked = true
        this.currentView = 'LinkedIn'
      }
    },
    {
      label: 'Twitter',
      icon: 'pi pi-twitter',
      command: () => {
        this.isBlocked = true
        this.currentView = 'Twitter'
      }
    },
    {
      label: 'Email',
      icon: 'pi pi-send',
      command: () => {
        this.currentView = 'Email'
        this.isBlocked = true
      }
    }
  ]

  @ViewChild('pnl', { static: false }) paneler?: ElementRef<Panel>
  @ViewChild('menu', { static: false }) menu?: Menu

  avatarUrl = ''
  avatarName = ''
  avatarEmail = ''

  private errorSubscription?: Subscription

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly navigationService: NavigationService,
    private readonly socialAuthService: SocialAuthService,
    private readonly sessionService: UserAuthService
  ) {
    /** */
  }

  ngOnInit(): void {
    this.accountForm = this.formBuilder.group({
      email: [''],
      password: ['']
    })
    this.setupObservers()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parentFocusedConnection'].currentValue === 1) {
      this.facebookAuthMenuItemIndex = 1
      // TODO not optimal because we create a new object each time
      this.socialAuthService.facebookPagesObservable$.subscribe({
        next: (pages) => {
          this.userFacebookPages = pages
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.errorSubscription?.unsubscribe()
  }

  private setupObservers(): void {
    this.sessionService.statusObservable$.subscribe({
      next: (status) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'Your account has been updated.'
        })
      }
    })
    this.socialAuthService.getInstagramLinkSuccessObservable$.subscribe({
      next: (success) => {
        this.facebookAuthMenuItemIndex = 2
      }
    })
    this.socialAuthService.userSocialAccountsObservable$.subscribe({
      next: (accounts) => {
        this.isAccountsLoading = false
        accounts.forEach((account) => {
          this.zoomConnected = account['zoom']
          this.facebookConnected = account['facebook']
          this.linkedinConnected = account['linkedin']
          this.mediumConnected = account['medium']
          this.youtubeConnected = account['youtube']
          this.twitterConnected = account['twitter']
        })
      }
    })
    this.socialAuthService.getTwitterAuthObservable$.subscribe(
      (isConnected) => {
        this.twitterConnected = isConnected
      }
    )
    this.socialAuthService.getConnectionLoadingObservable$.subscribe(
      (isLoading) => {
        this.isLoading = isLoading
      }
    )
    this.errorSubscription =
      this.socialAuthService.getErrorObservable$.subscribe((error) => {
        console.log(
          '🚀 ~ SettingsComponent ~ this.socialAuthService.getErrorObservable$.subscribe ~ error:',
          error
        )
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error
        })
      })
    this.socialAuthService.getMediumAuthObservable$.subscribe((isConnected) => {
      this.mediumConnected = isConnected
    })
    this.sessionService.userAccountObservable$.subscribe((user) => {
      this.avatarName = user.displayName ?? ''
      this.avatarUrl = user.photoURL ?? ''
      this.avatarEmail = user.email ?? ''
    })
    // Kickoffs
    this.sessionService.getUserAccount()
    this.socialAuthService.getAuthenticatedSocialAccounts()
  }

  onFacebookLogin(): void {
    // this.socialAuthService.signInWithFacebook();
    const params = {
      client_id: '883874189493049',
      redirect_uri: 'http://localhost:4200/facebook-callback',
      facebookScope: 'email'
    }

    window.location.href = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${params.client_id}&redirect_uri=${params.redirect_uri}&scope=${params.facebookScope}`
  }

  onTwitterLogin(): void {
    // this.socialAuthService.signInWithTwitter();
  }

  onMediumSubmit(): void {
    // this.socialAuthService.signInWithMedium(this.mediumIntegKey);
  }

  onFacebookPageSelected(): void {
    if (this.userSelectedFacebookPage !== undefined) {
      this.socialAuthService.getAssociatedInstagramAccounts(
        this.userSelectedFacebookPage
      )
    } else {
      console.log(
        '🚀 ~ SettingsComponent ~ onFacebookPageSelected ~ Error:',
        'Error'
      )
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select a Facebook page'
      })
    }
  }

  onLogoutClick(): void {
    this.confirmationService.confirm({
      message:
        'You are about to log out of the app.  Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'You have been logged out.',
          life: 1000
        })
        this.navigationService.navigateToLogin()
      },
      reject: (type: any) => {
        /** */
      }
    })
  }

  onEmailUpdate(): void {
    this.sessionService.updateEmail(this.accountForm.value.email)
  }

  resetPassword(): void {
    this.sessionService.resetPassword(this.accountForm.value.email)
  }
}
