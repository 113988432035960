export const environment = {
  production: true,
  aipiEnv: 'prod',
  aipiUrl: 'https://outbrand-dot-tensile-thinker-329113.uc.r.appspot.com',

  firebaseConfig: {
    apiKey: 'AIzaSyDPNHGz6U4HYtV7Q3FM_SPhPLnvr3YyuDk',
    authDomain: 'outbrand-fbf2f.firebaseapp.com',
    databaseURL: 'https://outbrand-fbf2f-default-rtdb.firebaseio.com',
    projectId: 'outbrand-fbf2f',
    storageBucket: 'outbrand-fbf2f.appspot.com',
    messagingSenderId: '202664362123',
    appId: '1:202664362123:web:e544365432703d309a548e',
    measurementId: 'G-X1SZTGF15J'
  }
}
