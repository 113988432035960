export const PostingPlatform = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  MEDIUM: 'medium',
  TIKTOK: 'tiktok',
  LINKEDIN: 'linkedin',
  ZOOM: 'zoom'
}
