<p-panel [toggleable]="true" [(collapsed)]="isCollapsed">
  <ng-template pTemplate="header">
    <div style="width: 100%; border-radius: 0.5rem; display: flex; margin-top: 1rem">
      <h1 [ngStyle]="{
        'color': isCollapsed ? 'grey': 'var(--primary-color)'
      }">Make changes to your draft</h1>
    </div>
  </ng-template>
  <strong *ngIf="blocks.length === 0" style="margin-top: 1rem;">You haven't added any blocks yet. Go to the interview to get started.</strong>
  <p-orderList *ngIf="blocks.length > 0" [value]="blocks"
    [listStyle]="{ 'width': '100%', 'overflow': 'auto' }" 
    [dragdrop]="true"
    controlsPosition="right">
    <ng-template let-block pTemplate="item">
      <p-card [style]="{ 'width': '100%', 'background-color': 'var(--color-light-grey)' }">
        <h3>{{block.question}}</h3>
        <pre *ngIf="!block.isEditMode"
          style="font-size: 1.5rem; font-family: var(--font-family);">{{block.answer}}</pre>
        <textarea *ngIf="block.isEditMode" pInputTextarea [autoResize]="true" rows="3" style="font-size: large;"
          [(ngModel)]="block.answer"></textarea>
        <div style="display: flex; flex-direction: row; justify-content: end; align-items: center;">
          <p-button *ngIf="!block.isEditMode" [style]="{ 'margin-top': '1rem', 'margin-left': 'auto' }"
            icon="pi pi-pencil" (onClick)="onEditBlock(block, true)"></p-button>
          <p-button *ngIf="!block.isEditMode" [style]="{ 'margin-top': '1rem', 'margin-left': '0.5rem' }"
            styleClass="p-button-danger" icon="pi pi-trash" (onClick)="onDeleteBlock(block)"></p-button>
          <p-button *ngIf="block.isEditMode" [style]="{ 'margin-top': '1rem', 'margin-left': '0.5rem' }" label="Save"
            icon="pi pi-save" (onClick)="onSaveBlock($event, block)"></p-button>
        </div>
      </p-card>
    </ng-template>
  </p-orderList>
  <ng-template pTemplate="footer">
    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding: 1rem">
      <p-button 
        p-button 
        label="Restart with a new topic" 
        icon="pi pi-refresh"
        styleClass="p-button-lg p-button-secondary p-button-outlined"
        [style]="{  'height': '48px'}"
        (onClick)="onStartOverClick()"></p-button>
      <p-button *ngIf="blocks.length > 0"
        p-button pTooltip="Click here if you are done with this step" styleClass="p-button-success p-button-lg"
        [style]="{ 'margin': 'auto', 'width': '200px', 'height': '48px' }" icon="pi pi-check"
        label="Get my content" (click)="outputConfirmation = true"></p-button>
      <p-button *ngIf="blocks.length === 0" p-button pTooltip="Click here if you are done with this step" styleClass="p-button-secondary p-button-lg"
        [style]="{ 'margin': 'auto', 'width': '200px', 'height': '48px' }" icon="pi pi-check"
        label="Get my content" [disabled]="true"></p-button>
    </div>
  </ng-template>
</p-panel>
<p-dialog [(visible)]="outputConfirmation" [modal]="true" [style]="{ 'width': '50%', 'margin': 'auto' }"
  header="Generate AI Content">
  <div class="row">
    <div style="display: flex; flex-direction: column;">
      <strong>We'll submit your current page to our AI to generate content. You can select the kind of content you would
        like to receive.</strong>
      <h2>Select Content to Create</h2>
      <div *ngFor="let contentType of contentTypes" class="field-checkbox">
        <br>
        <p-checkbox name="contentGroup" [value]="contentType" [disabled]="contentType.key === 'draft_to_video'"
          [(ngModel)]="selectedContentTypes" [inputId]="contentType.key"></p-checkbox>
        <label [for]="contentType.key" style="margin-left: 0.5rem;"
          [style]="{ 'font-size': '1.5rem', 'font-family': 'var(--font-family)' }">{{ contentType.name }}</label>
      </div>
    </div>
    <img src="../../../../assets/icons/social_post.png" alt="AI"
      style="width: 100px; height: 100px; margin: auto; margin-top: 12rem;">
  </div>
  <ng-template pTemplate="footer">
    <div style="display: flex; flex-direction: row;">
      <p-button (click)="outputConfirmation = false" label="Cancel"
        styleClass="p-button-secondary p-button-lg"></p-button>
      <p-button (click)="onDraftSubmitAccept()" label="Generate" styleClass="p-button-lg"></p-button>
    </div>
  </ng-template>
</p-dialog>
