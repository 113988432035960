<div style="min-height: 100vh">
  <div class="row">
    <h1>The Vault</h1>
    <p-button
      *ngIf="!isDraftView && !isContentView"
      label="Add New Topic"
      icon="pi pi-plus"
      styleClass="p-button-lg"
      (onClick)="showCreateTopicDialog = true"
    >
    </p-button>
  </div>
  <div
    style="
      background-color: var(--color-light-red);
      width: 100;
      border-radius: 0.5rem;
      height: 48px;
      align-items: center;
      display: flex;
      padding: 1rem;
      margin-bottom: 1rem;
      flex-direction: row;
      justify-content: space-between;
    "
  >
    <p>
      Tell us more about your business to get more topics and better content!
    </p>
    <p-button
      label="Get More Content"
      styleClass="p-button-outlined"
      (onClick)="onGetMoreClick()"
      [style]="{ 'margin-left': '1rem' }"
    ></p-button>
  </div>
  <p-table
    [value]="completeVaultList"
    [tableStyle]="{ 'min-width': '50rem' }"
    [style]="{ width: '80vw' }"
    [customSort]="true"
    (onSort)="onCustomSort($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th pSortableColumn="topic">
          Name<p-sortIcon field="topic"></p-sortIcon>
        </th>
        <th pSortableColumn="date">
          Date<p-sortIcon field="date"></p-sortIcon>
        </th>
        <th pSortableColumn="type" *ngIf="isContentView">
          Type<p-sortIcon field="type"></p-sortIcon>
        </th>
        <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>
          <img
            *ngIf="product.type !== 'content'"
            height="32"
            width="32"
            [src]="'../../../assets/icons/document_' + product.type + '.png'"
          />{{ product.name }}
          <img
            *ngIf="product.type === 'content'"
            height="32"
            width="32"
            [src]="
              '../../../assets/icons/document_' +
              product.type +
              '_' +
              product.data.type +
              '.png'
            "
          />{{ product.name }}
        </td>
        <td>
          {{
            product.type === "topic"
              ? product.data.topic
              : product.data.topic.topic
          }}
        </td>
        <td>{{ product.data.date.replace("T", " ") }}</td>
        <td *ngIf="isContentView">{{ product.displayType }}</td>
        <td>
          <p-button
            *ngIf="product.type === 'topic'"
            label="Create"
            styleClass="p-button-text"
            (onClick)="onCreateClick(product)"
          />
          <p-button
            *ngIf="product.type === 'draft'"
            label="Edit"
            styleClass="p-button-text"
            (onClick)="onEditClick(product)"
          />
          <p-button
            *ngIf="product.type === 'content'"
            label="View"
            styleClass="p-button-text"
            (onClick)="onViewClick(product)"
          />
          <p-button
            icon="pi pi-trash"
            styleClass="p-button-text p-button-secondary"
            [style]="{ 'margin-left': '6rem' }"
            (onClick)="onDeleteClick(product)"
          />
        </td>
      </tr>
    </ng-template>
  </p-table>
  <strong *ngIf="completeVaultList.length === 0" style="margin: 1rem"
    >You don't have any content yet. Start by creating a topic or a
    draft.</strong
  >
</div>
<p-dialog
  [(visible)]="isLoading"
  [modal]="true"
  [style]="{ width: '30%', margin: 'auto' }"
  [showHeader]="false"
>
  <div style="display: flex; flex-direction: column; align-items: center">
    <p-progressSpinner
      [style]="{
        width: '64px',
        height: '64px',
        margin: 'auto',
        'margin-top': '2rem'
      }"
    ></p-progressSpinner>
    <strong style="margin-top: 1rem">Please wait. Loading...</strong>
  </div>
</p-dialog>
<p-dialog
  [showHeader]="false"
  contentStyleClass="output-dialog"
  [(visible)]="showVaultContent"
  [draggable]="false"
  [resizable]="false"
  [style]="{ 'margin-left': '10rem', display: 'flex', overflow: 'auto' }"
>
  <app-outputdialog
    *ngIf="selectedVaultItemContent?.data.type !== 'tweets'"
    [title]="selectedVaultItemContent?.data.topic.topic"
    [bodyContent]="selectedVaultItemContent?.data.content"
    (onCancel)="showVaultContent = false"
    (onSaveBodyContent)="onSaveVaultContent($event)"
    (onCopy)="onCopyVaultContent($event)"
  >
  </app-outputdialog>
  <app-outputdialog
    *ngIf="selectedVaultItemContent?.data.type === 'tweets'"
    [title]="selectedVaultItemContent?.data.topic.topic"
    [bodyList]="selectedVaultItemContent?.data.content"
    (onCancel)="showVaultContent = false"
    (onSaveConentList)="onSaveVaultContent($event)"
    (onCopy)="onCopyVaultContent($event)"
  >
  </app-outputdialog>
</p-dialog>
<app-titledialog
  dialogTitle="Start creating your own content"
  dialogText="We'll save your topic for later and you can use it to build your own unique content."
  titlePlaceholder="Enter a title for your topic..."
  submitText="Add"
  cancelText="Cancel"
  [showDialog]="showCreateTopicDialog"
  (onHide)="showCreateTopicDialog = false"
  (onCancel)="showCreateTopicDialog = false"
  (onSubmit)="onTopicCreated($event)"
></app-titledialog>
