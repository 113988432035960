import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-titledialog',
  templateUrl: './titledialog.component.html',
  styleUrls: ['./titledialog.component.css']
})
export class TitledialogComponent {
  @Input() showDialog: boolean = false

  @Input() dialogTitle: string = ''
  @Input() dialogText: string = ''
  @Input() titlePlaceholder?: string

  @Input() cancelText: string = ''
  @Input() simpleSubmitText: string = ''
  @Input() submitText: string = ''

  @Output() onCancel = new EventEmitter<void>()
  @Output() onSimpleSubmit = new EventEmitter<string>()
  @Output() onSubmit = new EventEmitter<string>()

  submitTitle = ''

  cancel (): void {
    this.onCancel.emit()
    this.submitTitle = ''
  }

  simpleSubmit (): void {
    this.onSimpleSubmit.emit(this.submitTitle)
    this.submitTitle = ''
  }

  submit (): void {
    this.onSubmit.emit(this.submitTitle)
    this.submitTitle = ''
  }
}
