import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ConfirmationService, MessageService } from 'primeng/api'
import { Subscription } from 'rxjs'
import {
  ContentItem,
  ContentList
} from 'src/app/model/content/contentitem.model'
import { ContentResult } from 'src/app/model/content/contentresult.model'
import { ContentService } from 'src/app/service/content.service'
import { NavigationService } from 'src/app/service/util/navigation.service'
import { VaultService } from 'src/app/service/vault.service'

@Component({
  selector: 'app-outputs',
  templateUrl: './outputs.component.html',
  styleUrls: ['./outputs.component.css']
})
export class OutputsComponent implements OnInit, OnDestroy {
  private errorSubscription?: Subscription

  isContentLoading = false
  isLoading = false
  showHooksDialog = false
  numHooksSelected = 0

  hooksFormGroup?: FormGroup

  selectedHooks: string[] = []
  hooks: Array<{ value: string, selected: boolean }> = []
  timeline: Array<{
    type: string
  }> = []

  contentResult: ContentResult = {
    videoScripts: [],
    videoBullets: [],
    posts: [],
    tweets: {
      content: [],
      date: '',
      type: ''
    }
  }

  constructor (
    private readonly formBuilder: FormBuilder,
    private readonly vaultService: VaultService,
    private readonly contentService: ContentService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly navigationService: NavigationService
  ) {
    /** */
  }

  ngOnInit (): void {
    this.hooksFormGroup = this.formBuilder.group({
      hooks: this.formBuilder.array([])
    })
    this.contentService.contentObservable$.subscribe({
      next: (content: ContentResult) => {
        console.log('🚀 ~ OutputsComponent ~ ngOnInit ~ content:', content)
        // this.resetView();
        if (content?.videoScripts !== undefined) {
          this.contentResult = this.addHooksToContent(content)
        }
      }
    })
    this.contentService.isLoadingObservable$.subscribe({
      next: (isLoading: boolean) => {
        this.isLoading = isLoading
      }
    })
    this.errorSubscription = this.contentService.errorObservable$.subscribe({
      next: (error: string) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error
        })
      }
    })
    this.contentService.hooksObservable$.subscribe({
      next: (hooks: string[]) => {
        this.showHooksDialog = hooks.length > 0
        this.hooks = hooks.map((hook) => {
          return { value: hook, selected: false }
        })
      }
    })
    this.contentService.isContentLoadingObservable$.subscribe({
      next: (isContentLoading: boolean) => {
        this.isContentLoading = isContentLoading
      }
    })
    this.contentService.topicsObservable$.subscribe({
      next: (topics: Array<{ topic: string, objective: string }>) => {
        this.confirmationService.confirm({
          header: 'Your topics are ready!',
          message: 'Would you like to view them now?',
          acceptLabel: 'Yes! Take me there!',
          accept: () => {
            this.navigationService.navigateToVault('topic')
          }
        })
      }
    })
    // this.contentResult = {
    //   videoScripts: [
    //     {
    //       content:
    //         "Launching a successful software is one of the hardest things to do, but imagine standing at the level of the best in the game—it's the real deal. Only the best can see success here, and that's what you're aiming for. Yet, it's not all about being at the top; it's about tackling new challenges, like going back to being a beginner. No one wants to start over or be seen as a rookie, but it's required in everything new you do.\n",
    //       date: '2024-02-27T01:09:30',
    //       type: 'video_script',
    //     },
    //     {
    //       content:
    //         "The main lesson here is to recognize the multifaceted motivations behind launching a SaaS product. It's essential to understand these drivers for a more holistic and purpose-driven approach. Embrace the journey of launching a successful SaaS and remember, only the best can see success here.",
    //       date: '2024-02-27T01:09:41',
    //       type: 'video_script',
    //     },
    //   ],
    //   videoBullets: [],
    //   posts: [],
    //   tweets: {} as ContentList,
    // };
    // this.hooks = [
    //   {
    //     value: 'Launching a successful software is one of the hardest things to do.',
    //     selected: false,
    //   },
    //   {
    //     value: 'The main lesson here is to recognize the multifaceted motivations behind launching a SaaS product.',
    //     selected: false,
    //   }
    // ];
  }

  ngOnDestroy (): void {
    this.errorSubscription?.unsubscribe()
  }

  copyToClipboard (content: string): void {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Copied to clipboard',
          life: 1000
        })
      })
      .catch((err) => {
        console.error('Error copying text: ', err)
      })
  }

  saveCurrentContent (data: ContentItem | ContentList, showConfirmation = true): void {
    this.vaultService.saveOutputContent(data, showConfirmation)
  }

  onHooksSelected (): void {
    this.selectedHooks = this.hooks.filter((hook) => hook.selected).map((hook) => hook.value)
    this.numHooksSelected = this.selectedHooks.length
    if (this.numHooksSelected === 2) {
      this.addHooksToContent(this.contentResult)
      this.showHooksDialog = false
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select ONLY two hooks',
        life: 1500
      })
    }
  }

  private addHooksToContent (content: ContentResult): ContentResult {
    // apply hook to the first line of every item in the arrays in contentresult object
    content.videoScripts.forEach((script, index) => {
      if (this.selectedHooks[index] !== undefined && !script.content.includes(this.selectedHooks[index])) {
        script.content = this.selectedHooks[index] + '\n\n' + script.content
        this.saveCurrentContent(script, false)
      }
    })
    content.posts.forEach((post, index) => {
      if (this.selectedHooks[index] !== undefined && !post.content.includes(this.selectedHooks[index])) {
        post.content = this.selectedHooks[index] + '\n\n' + post.content
        this.saveCurrentContent(post, false)
      }
    })
    return content
  }

  getTopicsFromContent (): void {
    this.contentService.getTopicsFromContent()
  }
}
