<!-- <p-blockUI [target]="paneler" [blocked]="isBlocked">
  <i class="pi pi-lock" style="font-size: 3rem" fullscreen="false"></i>
</p-blockUI> -->

<h1>Settings</h1>
<div style="height: 80vh; margin-left: 1rem;">
  <p-menubar #menu [model]="menuItems"></p-menubar>
  <p-panel #pnl header="{{ currentView }}" class="container" [formGroup]="accountForm">
    <div *ngIf="currentView == 'Profile'" class="container">
      <div class="row">
        <p-avatar *ngIf="avatarUrl !== ''" [image]="avatarUrl" styleClass="mr-2" size="large" shape="circle"></p-avatar>
        <p-avatar *ngIf="avatarUrl == ''" icon="pi pi-user" styleClass="mr-2" size="large" shape="circle"></p-avatar>
        <div class="col" style="margin-left: 1rem">
          <strong style="margin: 0; padding: 0; line-height: 1;">{{ avatarName }}</strong>
          <p>{{ avatarEmail }}</p>
        </div>
      </div>
      <h3>Update Email</h3>
      <div class="wide-row">
        <input pInputText type="email" placeholder="Enter your new eamil" formControlName="email" />
        <p-button label="Update email" styleClass="p-button-secondary" (click)="onEmailUpdate()"></p-button>
      </div>
      <div class="wide-row">
        <input pInputText type="password" placeholder="Enter your new password" formControlName="password" />
        <p-button label="Update password" styleClass="p-button-secondary" (click)="resetPassword()"></p-button>
      </div>
      <br>
      <div class="wide-row">
        <a href="https://billing.stripe.com/p/login/3cscQP1dK8Srdbi288" class="btn p-button p-button-outlined p-button-danger" style="text-decoration: none;">Cancel Account</a>
        <p-button label="Sign Out" styleClass="p-button-danger" (click)="onLogoutClick()"></p-button>
      </div>
    </div>

    <div *ngIf="currentView == 'Your AI'" class="container">
        <app-persona></app-persona>
    </div>

    <div *ngIf="currentView == 'Zoom'" class="container">
      <div class="column" style="padding: 0.5rem;">
        <p-tag *ngIf="zoomConnected" severity="success" value="Connected"></p-tag>
        <p-tag severity="success" value="Coming Soon!"></p-tag>
        <p>
          Connect our app to your Zoom account and streamline your content efforts.
          Turn your old calls and coaching sessions or onboardings into leads.
          Get sales from your all those recordings you just have sitting around.
        </p>
        <p-footer>
          <p-button *ngIf="!zoomConnected" 
            label="Connect" 
            icon="pi pi-plus" 
            [disabled]="true"
            [loading]="isLoading"></p-button>
        </p-footer>
      </div>
    </div>

    <div *ngIf="currentView == 'Facebook'">
      <p-tag *ngIf="facebookConnected" severity="success" value="Connected"></p-tag>
      <p-tag severity="success" value="Coming Soon!"></p-tag>
      <p-steps [model]="faceebookAuthMenuItems" [readonly]="true" [activeIndex]="facebookAuthMenuItemIndex">
      </p-steps>
      <!-- First step conenct -->
      <div class="container" *ngIf="facebookAuthMenuItemIndex === 0">
        <div class="column" style="padding: 0.5rem;">
          <p>
            To start automating your Facebook and Instagram posts and saving time, simply click the button below to
            grant
            our app access to your Facebook account. By doing so, you'll be able to schedule and publish posts
            seamlessly,
            without any manual intervention.
          </p>
          <p-footer>
            <p-button 
              label="Connect" 
              icon="pi pi-plus" 
              [disabled]="true" 
              [loading]="isLoading">
            </p-button>
          </p-footer>
        </div>
      </div>
      <!-- Second Step. Facebook Page -->
      <div class="container" *ngIf="facebookAuthMenuItemIndex == 1">
        <div class="column">
          <p>Great. We have your facebook pages for you. Let's start by picking just one.</p>
          <p>If you would like to connect an Instagram account as well it is important that it is connected to the
            Facebook page you will select here.</p>
          <p-listbox [options]="userFacebookPages" [(ngModel)]="userSelectedFacebookPage" optionLabel="page"
            [style]="{'width':'100%'}">
            <ng-template let-page pTemplate="item">
              <div class="flex align-items-center gap-2">
                <h4>{{ page.name }}</h4>
              </div>
            </ng-template>
          </p-listbox>
          <p-footer>
            <p-button
              type="button" 
              label="Continue" 
              icon="pi pi-plus" 
              styleClass="p-button-success"
              [disabled]="userSelectedFacebookPage == undefined" 
              (click)="onFacebookPageSelected()"
              [loading]="isLoading">
            </p-button>
          </p-footer>
        </div>
      </div>
      <!-- Third Step. Instagram Account -->
      <div class="container" *ngIf="facebookAuthMenuItemIndex == 1">
        <div class="column">
          <p>Congrats your facebook is fully authenticated. Go ahead and close this dialog and proceed with setting your
            content on autopilot.</p>
        </div>
      </div>
    </div>


    <div *ngIf="currentView == 'LinkedIn'" class="container">
      <div class="column" style="padding: 0.5rem;">
        <p-tag *ngIf="linkedinConnected" severity="success" value="Connected"></p-tag>
        <p-tag severity="success" value="Coming Soon!"></p-tag>
        <p>
          Connect our app to your LinkedIn account and streamline your professional networking efforts. With automated
          posting, you can schedule and share valuable content with your connections, establishing thought leadership
          and boosting your professional brand. Experience the ease of automated LinkedIn posts!
        </p>
        <p-footer>
          <p-button 
            label="Connect" 
            icon="pi pi-plus" 
            [disabled]="true"
            [loading]="isLoading">
          </p-button>
        </p-footer>
      </div>
    </div>



    <div *ngIf="currentView == 'Twitter'">
      <div class="column" style="padding: 0.5rem;">
        <p-tag *ngIf="twitterConnected" severity="success" value="Connected"></p-tag>
        <p-tag severity="success" value="Coming Soon!"></p-tag>
        <p>
          Grant our app access to your Twitter account and take your tweeting game to the next level. With automated
          posting, you can schedule and publish tweets effortlessly, ensuring consistent engagement with your followers.
          Say goodbye to the hassle of manual tweeting and hello to effortless social media management!
        </p>
        <p-footer>
          <p-button 
            label="Connect" 
            icon="pi pi-plus" 
            [disabled]="true"
            [loading]="isLoading">
          </p-button>
        </p-footer>
      </div>
    </div>


    <div *ngIf="currentView == 'Email'" class="container">
      <div class="column" style="padding: 0.5rem;">
        <p-tag severity="success" value="Coming Soon!"></p-tag>
        <!-- <p-tag *ngIf="[emailConnected]" severity="success" value="Connected"></p-tag> -->
        <p>
          Connect our app to your email account and streamline your outreach efforts. With automated
          posting, you can schedule incredible offers with your audience, establishing thought leadership
          and boosting your brand. 
        </p>
        <p-footer>
          <p-button 
            label="Connect" 
            icon="pi pi-plus"  
            [disabled]="true"
            [loading]="isLoading">
          </p-button>
        </p-footer>
      </div>
    </div>
  </p-panel>
</div>
