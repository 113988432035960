<p-dialog 
  header="{{dialogTitle}}" 
  [(visible)]="showDialog" 
  [modal]="true" 
  [style]="{ width: '50vw' }" 
  [draggable]="false" 
  [resizable]="false"
  (onHide)="cancel()"
>
  <div style="display: flex; flex-direction: row; align-items: center">
    <i class="pi pi-exclamation-triangle" style="font-size: 2rem; margin: 1rem;"></i>
    <strong class="m-0">{{dialogText}}</strong>
  </div>
  <span class="p-float-label" style="flex: 1; padding-right: 1rem; margin-top: 1rem;">
    <input 
    pInputText
    class="p-inputtext-lg"
    id="draftSubmitTitle" 
    [style]="{'border': '1px solid #ced4da', 'border-radius': '0.25rem', 'width': '100%', 'margin-top': '1rem' }"
    [(ngModel)]="submitTitle"
    />
    <label htmlFor="draftSubmitTitle">{{titlePlaceholder}}</label>
  </span>
  <ng-template pTemplate="footer">
      <div style="display: flex; flex-direction: row; align-items: center; justify-content: end; margin-top: 2rem;">
        <p-button
          *ngIf="cancelText !== ''"
          p-button 
          label="{{cancelText}}"
          [style]="{'width': '200px', 'height': '32px', 'margin-right': '1rem'}"
          (click)="cancel()" 
        ></p-button>
        <p-button
          *ngIf="simpleSubmitText !== ''"
          p-button 
          label="{{simpleSubmitText}}"
          [style]="{'width': '200px', 'height': '32px', 'margin-right': '1rem'}"
          (click)="simpleSubmit()" 
        ></p-button>
        <p-button 
          p-button
          label="{{submitText}}" 
          [style]="{'width': '200px', 'height': '32px', 'margin-right': '1rem'}"
          (click)="submit()" 
        ></p-button>
      </div>
    </ng-template>
</p-dialog>
