import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AnimationOptions } from 'ngx-lottie'
import { ConfirmationService, MessageService } from 'primeng/api'
import { Observable } from 'rxjs'
import { ContentService } from 'src/app/service/content.service'
import { DashboardService } from 'src/app/service/dashboard/dashboard.service'
import { InterviewService } from 'src/app/service/interview.service'
import { VaultService } from 'src/app/service/vault.service'

@Component({
  selector: 'app-draftpanel',
  templateUrl: './draftpanel.component.html',
  styleUrls: ['./draftpanel.component.css']
})
export class DraftPanelComponent implements OnInit {
  @Input() isCollapsed = false
  @Output() collapseEvent = new EventEmitter<{ key: string, collapsed: boolean }>()
  @Output() resetEvent = new EventEmitter<boolean>()

  loadingObservable$!: Observable<boolean>

  isLoading = false

  options: AnimationOptions = {
    loop: true,
    path: '../../../../assets/animations/content.json'
  }

  currentTopic? = ''
  blocks: Array<{ question: string, answer: string, isClosing: boolean, isEditMode: boolean }> = []

  topicEditMode = false
  outputConfirmation = false

  contentTypes = [
    { name: 'Video Script', key: 'draft_to_video' },
    // { name: 'Tweet', key: 'twitter' },
    { name: 'Video Script Bullets', key: 'draft_to_video_bullets' },
    { name: 'Written Post', key: 'draft_to_post' },
    { name: 'Tweets', key: 'draft_to_twitter' }
  ]

  selectedContentTypes: Array<{ name: string, key: string }> = [
    { name: 'Video Script', key: 'draft_to_video' }
  ]

  color?: string

  constructor (
    private readonly dashboardService: DashboardService,
    private readonly messageService: MessageService,
    private readonly interviewService: InterviewService,
    private readonly vaultService: VaultService,
    private readonly contentService: ContentService,
    private readonly confirmationService: ConfirmationService
  ) { /** */ }

  ngOnInit (): void {
    this.vaultService.saveSuccessObservable$.subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'We have saved this for you'
        })
      }
    })
    this.interviewService.topicSelectedObservable$.subscribe({
      next: (topic) => {
        this.currentTopic = topic?.topic
      }
    })
    this.interviewService.addBlockObservable$.subscribe({
      next: (block) => {
        this.blocks = [
          ...this.blocks,
          { ...block, isEditMode: false }
        ]
      }
    })
    this.interviewService.blocksObservable$.subscribe({
      next: (blocks) => {
        if (blocks.length > 0) {
          this.collapseEvent.emit({ key: 'draft', collapsed: false })
          this.blocks = blocks.map((block) => {
            return {
              ...block,
              isEditMode: false
            }
          })
        }
      }
    })
    this.interviewService.isDraftLoadingObservable$.subscribe({
      next: (isLoading) => {
        this.isLoading = isLoading
      }
    })
    this.dashboardService.loadingObservable$.subscribe({
      next: (loading) => {
        this.isLoading = loading
      }
    })
  }

  async onDraftSubmitAccept (): Promise<void> {
    this.outputConfirmation = false
    await this.contentService.submitDraftForContent(
      this.blocks,
      {
        topic: this.currentTopic ?? '',
        objective: ''
      },
      this.selectedContentTypes.map((type) => type.key)
    )
    this.collapseEvent.emit({ key: 'draft', collapsed: true })
  }

  onEditBlock (block: { question: string, answer: string, isClosing: boolean, isEditMode: boolean }, editMode: boolean): void {
    const index = this.blocks.indexOf(block)
    if (index !== -1) {
      this.blocks[index].isEditMode = editMode
    }
  }

  onSaveBlock (event: Event, block: { question: string, answer: string, isClosing: boolean, isEditMode: boolean }): void {
    event.preventDefault()
    this.onEditBlock(block, false)
  }

  onDeleteBlock (block: { question: string, answer: string, isClosing: boolean, isEditMode: boolean }): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this block?',
      accept: () => {
        const index = this.blocks.indexOf(block)
        if (index !== -1) {
          this.blocks.splice(index, 1)
          this.blocks = [...this.blocks]
        }
      }
    })
  }

  onSaveDraft (): void {
    this.vaultService.saveDraft(this.blocks, {
      topic: this.currentTopic ?? '',
      objective: ''
    }, true)
  }

  onStartOverClick (): void {
    this.blocks = []
    this.interviewService.onTopicSelected({
      topic: '',
      objective: ''
    })
    this.interviewService.getRandomTopicsSubset()
    this.resetEvent.emit(true)
  }

  onEditTopicSave (): void {
    this.topicEditMode = false
  }
}
