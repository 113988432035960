import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { MessageService } from 'primeng/api'
import { Subscription, filter } from 'rxjs'
import { InterviewService } from 'src/app/service/interview.service'

@Component({
  selector: 'app-interviewpanel',
  templateUrl: './interviewpanel.component.html',
  styleUrls: ['./interviewpanel.component.css']
})
export class InterviewpanelComponent implements OnInit, OnDestroy {
  @Input() isCollapsed = true
  @Output() collapseEvent = new EventEmitter<{ key: string, collapsed: boolean }>()
  @Output() questionAnsweredEvent = new EventEmitter<boolean>()

  isQuestionLoading = false
  isSuggestionsLoading = false
  currentQuestion?: string
  currentInput = ''
  progress = 3
  showHelpDialog = false
  isAutoAIAnswer = false

  routerSubscription!: Subscription

  constructor (
    private readonly interviewService: InterviewService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { /** */ }

  ngOnInit (): void {
    // when we leave we delete values
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const currentRoute = this.route.snapshot.url.join('/')
      if (currentRoute === 'dashboard') {
        this.isAutoAIAnswer = false
      }
    })
    this.interviewService.contentSuggestionLoadingObservable$.subscribe((isLoading: boolean) => { this.isSuggestionsLoading = isLoading })
    this.interviewService.contentSuggestionsObservable$.subscribe((suggestion: string) => { this.currentInput = suggestion })
    this.interviewService.contentQuestionLoadingObservable$.subscribe((isLoading: boolean) => { this.isQuestionLoading = isLoading })
    this.interviewService.nextContentQuestionObservable$.subscribe((question: string) => {
      this.currentQuestion = question
      this.currentInput = ''
      if (this.isAutoAIAnswer) { this.onGetSuggestionClick() }
    })
    this.interviewService.topicSelectedObservable$.subscribe((topic: { topic: string, objective: string } | undefined) => { this.progress = 5 })
    this.interviewService.getBrandNewContentQuestion()
  }

  ngOnDestroy (): void {
    // this.routerSubscription.unsubscribe();
  }

  onSkipClick (): void {
    this.currentInput = ''
    this.onSubmitClick()
  }

  onSubmitClick (): void {
    if (this.currentInput !== '') {
      this.progress += 25
      this.questionAnsweredEvent.emit(true)

      if (this.progress >= 100) {
        this.progress = 100
        this.collapseEvent.emit({ key: 'interview', collapsed: true })
      }
      this.messageService.add({
        severity: 'success',
        detail: 'We\'ve added this to your draft',
        life: 1000
      })
    }

    this.interviewService.addContentQuestionToDraft(
      this.currentInput,
      this.currentQuestion
    )
  }

  onGetSuggestionClick (): void {
    this.interviewService.getSuggestion(this.currentQuestion)
  }
}
