<p-panel [toggleable]="true" [(collapsed)]="isCollapsed">
  <ng-template pTemplate="header">
    <div style="width: 100%; border-radius: 0.5rem; display: flex;">
      <h1 [ngStyle]="{
        'color': isCollapsed ? 'gray': 'var(--primary-color)'
      }">Jump into your interview</h1>
    </div>
  </ng-template>
  <div>
    <div style="background-color: var(--color-light-grey); width: 100%; border-radius: 0.5rem; display: flex; padding: 1rem; margin-bottom: 1rem; flex-direction: row; justify-content: space-between; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); border: 1px solid var(--color-light-grey);">
      <h3 style="width: 25%;">Get help from your AI CEO</h3>
      <div style="flex-grow: 1; margin-left: 5rem">
        <p style="font-size: medium;">Great content usually answers 4 questions. We'll keep track for you here.</p>
        <p-progressBar [value]="progress" [style]="{ 'width': '100%', 'margin-top': '0.5rem' }"></p-progressBar>
        <div style="display: flex; flex-direction: row; width: 100%; margin-top: 0.5rem; align-items: center;">
          <p-inputSwitch [(ngModel)]="isAutoAIAnswer"></p-inputSwitch>
          <p *ngIf="!isAutoAIAnswer" style="font-size: medium; margin-left: 1rem;">Your AI CEO will not give you answers by default</p>
          <p *ngIf="isAutoAIAnswer" style="font-size: medium; margin-left: 1rem;">Your AI CEO WILL give you answers by default</p>
        </div>
        <p-button p-button pTooltip="Click here for help and examples" 
        styleClass="p-button-danger p-button-text p-button-lg"
          [style]="{ 'padding': '0', 'width':'100%', 'text-align': 'start' }" 
          label="Need help answering the questions?"
          icon="pi pi-question-circle" iconPos="right"
          (click)="showHelpDialog = true"></p-button>
      </div>
    </div>
    <p-skeleton *ngIf="isQuestionLoading" width="100%" height="15rem"
      [style]="{ 'margin': 'auto', 'margin-top': '1rem' }"></p-skeleton>
    <div *ngIf="!isQuestionLoading && this.currentQuestion !== undefined" [style]="{ 'padding': '0.5rem' }">
      <h1 style="margin-top: 3rem">{{ currentQuestion }}</h1>
      <p-skeleton *ngIf="isSuggestionsLoading" width="100%" height="8rem" [style]="{ 'margin-top': '1rem' }"></p-skeleton>
      <textarea *ngIf="!isSuggestionsLoading" pInputTextarea [autoResize]="true" rows="1" class="styled-textarea"
        placeholder="Type your answer here..." [(ngModel)]="currentInput"></textarea>
      <p-divider></p-divider>
      <div style="display: flex; flex-direction: row;">
        <p-button p-button *ngIf="this.currentQuestion !== ''"
          styleClass="p-button-secondary"
          [style]="{ 'margin': 'auto', 'margin-top': '1rem', 'width': '200px' }"
          label="Get AI Suggestion" (click)="onGetSuggestionClick()" />
      </div>
    </div>
    <!-- <br>
        <h3>Add Blocks</h3>
        <p-button [style]="{ 'margin': 'auto', 'margin-top': '1rem', 'width': '100%', 'height': '64px' }" label="Add Empty Block"
        styleClass="p-button-lg p-button-secondary" (click)="onAddEmptyBlockClick()" /> -->
    <!-- <p-button [style]="{ 'margin': 'auto', 'margin-top': '1rem', 'width': '100%', 'height': '64px' }" label="Add Your Content as Block"
          styleClass="p-button-lg p-button-info" (click)="onYourContentBlockClick()" /> -->
  </div>
  <ng-template pTemplate="footer">
    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-top: 5rem">
      <p-button 
        *ngIf="this.currentQuestion !== undefined" 
        p-button styleClass="p-button-text p-button-lg"
        [style]="{ 'margin': 'auto' }"
        label="Skip" (click)="onSkipClick()" />
        <div></div>
      <div>
        <p-button p-button pTooltip="Click here if you are done with this step"
          [style]="{ 'margin': 'auto', 'width': '200px', 'height': '48px', 'margin-right': '1rem' }"
          icon="pi pi-chevron-right" label="Go To Next Step"
          (click)="collapseEvent.emit({ key: 'interview', collapsed: true })"></p-button>
        <p-button 
          *ngIf="this.currentQuestion !== undefined" 
          p-button
          pTooltip="Click here if you are done with this step" styleClass="p-button-success"
          [style]="{ 'margin': 'auto', 'width': '200px', 'height': '48px' }" icon="pi pi-save" label="Save Answer"
          (click)="onSubmitClick()"></p-button>
      </div>
    </div>
  </ng-template>
</p-panel>
<p-dialog [(visible)]="showHelpDialog" [modal]="true" [style]="{ 'width': '40%', 'margin': 'auto' }" header="How to create content with Outbrand">
  <p>
    Now that you have a topic in mind, let's answer a few questions to get you started creating content.
  </p>
  <p>
    The purpose of the interview is to get all that wisdom out of you, because we know it's already there. 
  </p>
  <br>
  <strong>The AI interview is based on the following things:</strong>
  <p>
    The topic, the questions that we know that currently create the best content, and the answers that you give us.
  </p>
  <p>
    Think about it as if you are interviewed in a magazine or a podcast that you want to share your knowledge and expertise with the world. 
  </p>
  <p>
    Using the interview, your conant draft will be created. From this draft all your content is generated based on the most conducive combination of the knowledge the OurBrand already about you, your answers, and what currently works best.
  </p>
  <br>
  <strong>Here's how to answer the questions to create the best results:</strong>
  <p>
    Try to answer each question in about 30-100 words. You can use the following pronoun: You, I, him,they. 
  </p>
  <p>
    So either you talk about yourself, your audience, someone specific. 
  </p>
  <p>
    Try to answer with detail and describe day to day scenes, or specific situations, thoughts and feelings. 
  </p>
  <br>
  <strong>When each question pops:</strong>
  <p>
    You can either answer and press submit, ask to skip and get another question, or ask OutBrand to suggest an answer for you because it already knows so much about your business and about your approach, when you get the response you can even edit that if you want to perfect it. 
  </p>
  <p>
    Usually we recommend answering 3-5 questions. The questions go progress as a content flow should and then after 3-5 questions answered its recommended to go to a closing question, which is a question that we use to end the content session. 
  </p>
  <p>
    This question helps you “end the loop”. 
  </p>
  <p>
    If you want you can add your own blocks without being asked a specific question, this is for when you are just inspired to add context about the topic.  
  </p>
  <p>
    You can answer more questions if you want to. 
  </p>
  <ng-template pTemplate="footer">
    <div style="display: flex; flex-direction: row; justify-content: end; width: 100%; padding: 1rem">
      <p-button p-button pTooltip="Click here if you are done with this step"
       icon="pi pi-check" label="OK"
       styleClass="p-button-lg"
        (click)="showHelpDialog = false"></p-button>
    </div>
  </ng-template>
</p-dialog>
